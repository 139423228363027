
import axios from "axios";
import Swal from 'sweetalert2';

var CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");

const API_URL0 = process.env.REACT_APP_API_API_URL
const API_URL = process.env.REACT_APP_API_AUTH_URL
const API_URL1 = process.env.REACT_APP_API_EMAIL_URL
const API_URL2 = process.env.REACT_APP_API_EMAIL_URL_IMG



const register_denuncia = (
  denuncia_user_id,
  denuncia_tipo,
  dataDevice,
  denuncia_Descripcion,
  denuncia_foto,
  denuncia_accuracy,
  denuncia_currentLatitude,
  denuncia_currentLongitude,
  denuncia_pais,
  denuncia_Nombres,
  denuncia_Apellidos,
  denuncia_Fono,
  denuncia_Email,
  denuncia_Usuario,
  denuncia_Role,
  denuncia_Prioridad,
  denuncia_Entidad,
  denuncia_dias,
  calle1,
  nuncalle,
  calle2,
  accessToken) => {

  let urlAux = API_URL + "ingresodenuncia";
  return axios.post(urlAux, {
    denuncia_user_id,
    denuncia_tipo,
    dataDevice,
    denuncia_Descripcion,
    denuncia_foto,
    denuncia_accuracy,
    denuncia_currentLatitude,
    denuncia_currentLongitude,
    denuncia_pais,
    denuncia_Nombres,
    denuncia_Apellidos,
    denuncia_Fono,
    denuncia_Email,
    denuncia_Usuario,
    denuncia_Role,
    denuncia_Prioridad,
    denuncia_Entidad,
    denuncia_dias,
    calle1,
    nuncalle,
    calle2,
  },
    {
      headers: { "x-access-token": accessToken }
    }).then(function (response) {
      if (response.data) {
        try {
          return response.data;
        } catch (error) {
          console.error('error: ', error);
        }
      }
    })
    .catch(function (error) {

      let coderror = error.response.status;
      console.log("coderror:", coderror)

      if (coderror === 404) {
        return coderror;
      }

      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      }).then(function () {
        logout();
        window.location.href = "/";
      })

    });
};


const register_user = (
  user_nombre,
  user_apellido,
  user_fono,
  user_email,
  user_avatar,
  user_usuario,
  user_password,
  user_terminos,
  user_entidad,
  user_respuesta_sms,
  user_currentLatitude,
  user_currentLongitude,
  user_accuracy,
  user_pais
) => {
  return axios.post(API_URL + "signup", {
    user_nombre,
    user_apellido,
    user_fono,
    user_email,
    user_avatar,
    user_usuario,
    user_password,
    user_terminos,
    user_entidad,
    user_respuesta_sms,
    user_currentLatitude,
    user_currentLongitude,
    user_accuracy,
    user_pais
  }).then(function (response) {
    if (response.data) {
      try {

        return response.data.message;
      } catch (error) {
        console.error('signup:', error);
      }
    }
  })
    .catch(function (error) {
      console.log(error.response.data);
      let coderror = error.response.status;
      console.log(coderror);
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};


const login = (username, password, dataDevice) => {

  return axios.post(API_URL + "signin", {
    username: username,
    password: password,
    dataDevice: dataDevice
  })
    .then(function (response) {


      var key = process.env.REACT_APP_KEY
      if (response.data.accessToken) {
        try {

          //localStorage.setItem("userSGSMAHO", JSON.stringify(response.data));
          var encrypted = CryptoJS.AES.encrypt(JSON.stringify(response.data), key);
          localStorage.setItem("userSGSMAHO", encrypted);

        } catch (error) {
          console.error(error);
        }
      }
      return response.data;
    })
    .catch(function (error) {
      let coderror = error.response.status;
      console.log(error.response.data.message);
      console.log(coderror);
      Swal.fire({
        icon: (coderror == 403) ? 'warning' : 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        text: (coderror == 403) ? "Usuario: (" + username + "), No olvide revisar su bandeja de entrada, para verificar su email en el Sistema." : "No se pudo verificar al usuario: (" + username + ")",
        timer: 10000
      })

    });
}

//          withCredentials: false,
//headers: {
//  'Access-Control-Allow-Origin' : '*',
//  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//}

const Url_caso = (numcaso, entidad, accessToken) => {
  return axios.post(API_URL2 + "image-search", {
    entidad: numcaso,
    numcaso: entidad
  }, {
    headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
  })
    .then(function (response) {
      if (response) {
        try {

          if (response.data.resources.length > 0) {
            return response.data.resources[0].url;
          } else {
            return process.env.REACT_APP_LOGOEMPRESA
          }
          //console.log(response.data)



        } catch (error) {
          console.error(error);
        }
      }
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const ingresoantecedenteCierre = (
  antecedente_antecedentes,
  antecedente_denuncia_id,
  antecedente_user_id,
  antecedente_username,
  antecedente_avatar,
  antecedente_foto,
  antecedente_pais,
  antecedente_accuracy,
  antecedente_currentLatitude,
  antecedente_currentLongitude,
  antecedente_medidas,
  antecedente_participantes,
  antecedente_detenidos,
  antecedente_cantidadDetenidos,
  denuncia_dias,
  accessToken
) => {
  return axios.post(API_URL + "ingresoantecedenteCierre", {
    antecedente_antecedentes: antecedente_antecedentes,
    antecedente_denuncia_id: antecedente_denuncia_id,
    antecedente_user_id: antecedente_user_id,
    antecedente_username: antecedente_username,
    antecedente_avatar: antecedente_avatar,
    antecedente_foto: antecedente_foto,
    antecedente_pais: antecedente_pais,
    antecedente_accuracy: antecedente_accuracy,
    antecedente_currentLatitude: antecedente_currentLatitude,
    antecedente_currentLongitude: antecedente_currentLongitude,
    antecedente_medidas: antecedente_medidas,
    antecedente_participantes: antecedente_participantes,
    antecedente_detenidos: antecedente_detenidos,
    antecedente_cantidadDetenidos: antecedente_cantidadDetenidos,
    denuncia_dias: denuncia_dias
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const ingresoantecedente = (
  antecedente_antecedentes,
  antecedente_denuncia_id,
  antecedente_user_id,
  antecedente_username,
  antecedente_avatar,
  antecedente_foto,
  antecedente_pais,
  antecedente_accuracy,
  antecedente_currentLatitude,
  antecedente_currentLongitude,
  denuncia_dias,
  accessToken
) => {


  return axios.post(API_URL + "ingresoantecedente", {
    antecedente_antecedentes: antecedente_antecedentes,
    antecedente_denuncia_id: antecedente_denuncia_id,
    antecedente_user_id: antecedente_user_id,
    antecedente_username: antecedente_username,
    antecedente_avatar: antecedente_avatar,
    antecedente_foto: antecedente_foto,
    antecedente_pais: antecedente_pais,
    antecedente_accuracy: antecedente_accuracy,
    antecedente_currentLatitude: antecedente_currentLatitude,
    antecedente_currentLongitude: antecedente_currentLongitude,
    denuncia_dias: denuncia_dias
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}


const ingresoentidad = (
  entidad_name,
  entidad_direccion,
  entidad_accuracy,
  entidad_currentLatitude,
  entidad_currentLongitude,
  entidad_email,
  entidad_telefono,
  entidad_nomcontacto,
  entidad_logo,
  entidad_pais,
  entidad_TipoEntidad) => {

  return axios.post(API_URL + "ingresoentidad", {
    entidad_name: entidad_name,
    entidad_direccion: entidad_direccion,
    entidad_accuracy: entidad_accuracy,
    entidad_currentLatitude: entidad_currentLatitude,
    entidad_currentLongitude: entidad_currentLongitude,
    entidad_email: entidad_email,
    entidad_telefono: entidad_telefono,
    entidad_nomcontacto: entidad_nomcontacto,
    entidad_logo: entidad_logo,
    entidad_pais: entidad_pais,
    entidad_TipoEntidad: entidad_TipoEntidad
  })
    .then(function (response) {

      return response.data;
    })
    .catch(function (error) {
      console.log('ingresoentidad error: ', error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}


const logout = () => {
  try {
    if (localStorage.getItem("userSGSMAHO") !== null) {
      localStorage.removeItem("userSGSMAHO");
      console.log('removeItem: userSGSMAHO')
    }

  } catch (error) {
    console.log('[logout] error:', error);

  }

};

const verifyUser = (code) => {

  return axios.get(API_URL1 + "email/confirm/" + code).then((response) => {
    return response.data;
  });
};


const usertel = (email, username) => {
  return axios.post(API_URL1 + "api/auth/usertel/", {
    email: email,
    username: username
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {

      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};


const contactoemergencia = (
  user_id,
  user_nombre,
  user_fono,
  user_contacto1,
  user_contacto2,
  user_email,
  user_usuario,
  user_pais,
  user_currentLatitude,
  user_currentLongitude,
  user_accuracy
) => {
  return axios.post(API_URL1 + "api/auth/contactoemergencia/", {
    user_id,
    user_nombre,
    user_fono,
    user_contacto1,
    user_contacto2,
    user_email,
    user_usuario,
    user_pais,
    user_currentLatitude,
    user_currentLongitude,
    user_accuracy
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(JSON.stringify(error.response.data.message));
      let coderror = error.response.status;
      console.log(JSON.stringify(coderror));
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};


const cambiopass = (
  user_id,
  user_nombre,
  user_fono,
  user_email,
  user_usuario,
  user_password,
  user_respuesta_sms,
  user_pais,
  user_currentLatitude,
  user_currentLongitude,
  user_accuracy
) => {
  return axios.post(API_URL1 + "api/auth/cambiopass/", {
    user_id,
    user_nombre,
    user_fono,
    user_email,
    user_usuario,
    user_password,
    user_respuesta_sms,
    user_pais,
    user_currentLatitude,
    user_currentLongitude,
    user_accuracy
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(JSON.stringify(error.response.data.message));
      let coderror = error.response.status;
      console.log(JSON.stringify(coderror));
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};

const cambioRol = (
  user_id,
  user_usuario,
  user_email,
  user_nombre,
  user_rolNew,
  unidad_id,
  unidMunicipalNom,
  accessToken
) => {
  //console.log(user_rolNew)
  return axios.post(API_URL1 + "api/auth/cambioRol/", {
    user_id,
    user_usuario,
    user_email,
    user_nombre,
    user_rolNew,
    unidad_id,
    unidMunicipalNom,
    accessToken
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(JSON.stringify(error.response.data.message));
      let coderror = error.response.status;
      console.log(JSON.stringify(coderror));
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};

const ingresoPlan = (
  tipo_planes,
  user_id,
  entidades,
  currentLatitude,
  currentLongitude,
  currentaccuracy,
  ubica,
  descripcion,
  accessToken
) => {
  return axios.post(API_URL1 + "api/auth/ingresoPlan/", {
    tipo_planes,
    user_id,
    entidades,
    currentLatitude,
    currentLongitude,
    currentaccuracy,
    ubica,
    descripcion,
    accessToken
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(JSON.stringify(error.response.data.message));
      let coderror = error.response.status;
      console.log(JSON.stringify(coderror));
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};


const cambioEstado = (
  user_id,
  user_usuario,
  user_email,
  user_nombre,
  user_estadoNew,
  accessToken
) => {
  return axios.post(API_URL1 + "api/auth/cambioEstado/", {
    user_id,
    user_usuario,
    user_email,
    user_nombre,
    user_estadoNew,
    accessToken
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(JSON.stringify(error.response.data.message));
      let coderror = error.response.status;
      console.log(JSON.stringify(coderror));
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};

const cambioEstadoPlan = (
  plan_id,
  plan_estadoNew,
  accessToken
) => {
  return axios.post(API_URL1 + "api/auth/cambioEstadoPlan/", {
    plan_id,
    plan_estadoNew,
    accessToken
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(JSON.stringify(error.response.data.message));
      let coderror = error.response.status;
      console.log(JSON.stringify(coderror));
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};


const cambioavatar = async (
  user_id,
  user_nombre,
  user_fono,
  user_email,
  user_usuario,
  user_avatar,
  user_pais,
  user_currentLatitude,
  user_currentLongitude,
  user_accuracy
) => {
  try {
    const response = await axios.post(API_URL1 + "api/auth/cambioavatar/", {
      user_id,
      user_nombre,
      user_fono,
      user_email,
      user_usuario,
      user_avatar,
      user_pais,
      user_currentLatitude,
      user_currentLongitude,
      user_accuracy
    });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const insertafotoAntecedente = async (
  antecedente_id,
  denuncia_id,
  user_id,
  antecedente_foto,
  accessToken
) => {
  try {
    let urlAux = API_URL + "ingresodenuncia/ingfotoAntecedente";
    const response = await axios.post(urlAux, {
      antecedente_id,
      denuncia_id,
      user_id,
      antecedente_foto
    },
      {
        headers: { "x-access-token": accessToken }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const insertafotoDenuncia = async (
  denuncia_id,
  username,
  email,
  nombres,
  denuncia_foto,
  accessToken
) => {
  try {
    let urlAux = API_URL + "ingresodenuncia/ingfoto";
    const response = await axios.post(urlAux, {
      denuncia_id,
      username,
      email,
      nombres,
      denuncia_foto
    },
      {
        headers: { "x-access-token": accessToken }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDen = async (
  estado,
  entidad,
  varDay,
  derivacion,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDen";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      varDay,
      derivacion
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    if (response != undefined) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenMis = async (
  estado,
  entidad,
  user_id,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenMis";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      user_id
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_hora = async (
  estado,
  entidad,
  varDay,
  denuncia_tipo,
  utc,
  derivacion,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_hora";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      varDay,
      denuncia_tipo,
      utc,
      derivacion
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_horaTipo = async (
  varEntidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  derivacion,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_horatipo";
    const response = await axios.post(urlAux, {
      varEntidad,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      utc,
      derivacion
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_horaTipo1 = async (
  varEntidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  derivacion,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_horatipo1";
    const response = await axios.post(urlAux, {
      varEntidad,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      utc,
      derivacion
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_diaS = async (
  estado,
  entidad,
  varDay,
  denuncia_tipo,
  utc,
  derivacion,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_diaS";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      varDay,
      utc,
      derivacion,
      denuncia_tipo
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_FechaHora = async (
  estado,
  entidad,
  varDay,
  utc,
  derivacion,
  accessToken
) => {
  try {


    let urlAux = API_URL + "aggregate_FechaHora";
    const response = await axios.post(urlAux, {
      estado,
      varDay,
      utc,
      derivacion,
      entidad
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_FechaHora1 = async (
  estado,
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  derivacion,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_FechaHora1";
    const response = await axios.post(urlAux, {
      estado,
      utc,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      entidad,
      derivacion
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listAntecedentes = async (
  denuncia_id,
  accessToken
) => {
  try {
    let urlAux = API_URL + "listAntecedentes/";
    const response = await axios.post(urlAux, {
      denuncia_id
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listUsers = async (
  entidad,
  rol,
  accessToken
) => {
  try {
    let urlAux = API_URL + "listUsers/";
    const response = await axios.post(urlAux, {
      entidad,
      rol
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listPlanes = async (
  activo,
  accessToken
) => {
  try {

    let urlAux = API_URL + "listPlanes/";
    const response = await axios.post(urlAux, {
      activo
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listUsersPerfil = async (
  entidad,
  activo,
  accessToken
) => {
  try {
    let urlAux = API_URL + "listUsersPerfil/";
    const response = await axios.post(urlAux, {
      entidad,
      activo
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listUsersPlan = async (
  entidad,
  roles,
  accessToken
) => {
  try {
    let urlAux = API_URL + "listUsersPlan/";
    const response = await axios.post(urlAux, {
      entidad,
      roles
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listUsersEstado = async (
  entidad,
  accessToken
) => {
  try {
    let urlAux = API_URL + "listUsersEstado/";
    const response = await axios.post(urlAux, {
      entidad
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listUserPlanOnOF = async (
  dato,
  accessToken
) => {
  try {
    let urlAux = API_URL + "planes/listUserPlanOnOF/";
    const response = await axios.post(urlAux, {
      dato
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(error)
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const findUsergeotracks = async (
  user_id,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "findUsergeotracks";
    const response = await axios.post(urlAux, {
      user_id,
      Fecha_Ini,
      Fecha_Fin
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const findSeguridadgeotracks = async (
  rol_seg,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "findSeguridadgeotracks";
    const response = await axios.post(urlAux, {
      rol_seg,
      Fecha_Ini,
      Fecha_Fin
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const findMasgeotracks = async (
  user_id,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "findMasgeotracks";
    const response = await axios.post(urlAux, {
      user_id,
      Fecha_Ini,
      Fecha_Fin
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const lisDenCerrada = async (
  estado,
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerrada";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenCerradaSinAnt = async (
  estado,
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaSinAnt";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenCerradaImagenFechas = async (
  estado,
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaImagenFechas";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenCerradaImagenNumCaso = async (
  estado,
  entidad,
  NunCaso,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaImagenNumCaso";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      NunCaso,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenCerradaGrabacionFechas = async (
  estado,
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaGrabacionFechas";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenCerradaGrabacionNumCaso = async (
  estado,
  entidad,
  NunCaso,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaGrabacionNumCaso";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      NunCaso,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const regcall = async (
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "callsms/regcall";

    const response = await axios.post(urlAux, {
      Fecha_Ini,
      Fecha_Fin,
      accessToken
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};
const regcalltel = async (
  aux_tipo,
  NunTel,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "callsms/regcalltel";
    const response = await axios.post(urlAux, {
      aux_tipo,
      NunTel,
      accessToken
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const regsms = async (
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "callsms/regsms";

    const response = await axios.post(urlAux, {
      Fecha_Ini,
      Fecha_Fin,
      accessToken
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};
const regsmstel = async (
  aux_tipo,
  NunTel,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "callsms/regsmstel";
    const response = await axios.post(urlAux, {
      aux_tipo,
      NunTel,
      accessToken
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const ingresoturno = (
  user_id,
  tipo_user,
  lat_ini,
  lng_ini,
  currentaccuracy_ini,
  tipo_movil,
  codigo_movil,
  kmIni_movil,
  codigo_radio,
  asignacion_user,
  dataDevice_Ini,
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/ingresoturno", {
    user_id: user_id,
    tipo_user: tipo_user,
    lat_ini: lat_ini,
    lng_ini: lng_ini,
    currentaccuracy_ini: currentaccuracy_ini,
    tipo_movil: tipo_movil,
    codigo_movil: codigo_movil,
    kmIni_movil: kmIni_movil,
    codigo_radio: codigo_radio,
    asignacion_user: asignacion_user,
    dataDevice_Ini: dataDevice_Ini
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const salidaturno = (
  user_id,
  tipo_user,
  lat_ter,
  lng_ter,
  currentaccuracy_ter,
  kmTer_movil,
  novedadesturno,
  dataDevice_Ter,
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/salidaturno", {
    user_id: user_id,
    tipo_user: tipo_user,
    lat_ter: lat_ter,
    lng_ter: lng_ter,
    currentaccuracy_ter: currentaccuracy_ter,
    kmTer_movil: kmTer_movil,
    novedadesturno: novedadesturno,
    dataDevice_Ter: dataDevice_Ter
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const enturno = (
  user_id,
  tipo_user,
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/enturno", {
    user_id: user_id,
    tipo_user: tipo_user
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const listTurnos = (
  estado_turno,
  tipo_user,
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/listturnos", {
    estado_turno: estado_turno,
    tipo_user: tipo_user
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const findUserGeoUltima = (
  userid,
  username,
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/findusergeoultima", {
    userid: userid,
    username: username
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const listTurnosDisponible = (
  estado_turno,
  tipo_user,
  disponible,
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/listturnosdisponible", {
    estado_turno: estado_turno,
    tipo_user: tipo_user,
    disponible: disponible,
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const findUserGeoUltimaGeo = (
  lat,
  lng,
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/findusergeoultimageo", {
    lat: lat,
    lng: lng
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}



const insertadespacho = (
  denuncia_id,
  denunciaid,
  user_id,
  estado_asignacion,
  Codigo_Operador,
  Username_Operador,
  UserOperador_id,
  Codigo_Seguridad,
  Username_Seguridad,
  UserSeguridad_id,
  Seguridad_Avatar,
  Tipo_movil,
  Codigo_movil,
  Asignacion_user,
  Distancia_Asignacion,
  Fechahora_Ult_Pos_Asignacion,
  Location_Asignacion,
  accuracy,
  denuncia_dias,
  LatProc,
  LngProc,
  TelefenoSeguridad,
  nomprioridad,
  nameDescripcion,
  Turno_user_id,
  accessToken
) => {
  return axios.post(API_URL0 + "despacho/insertadespacho", {
    denuncia_id: denuncia_id,
    denunciaid: denunciaid,
    user_id: user_id,
    estado_asignacion: estado_asignacion,
    Codigo_Operador: Codigo_Operador,
    Username_Operador: Username_Operador,
    UserOperador_id: UserOperador_id,
    Codigo_Seguridad: Codigo_Seguridad,
    Username_Seguridad: Username_Seguridad,
    UserSeguridad_id: UserSeguridad_id,
    Seguridad_Avatar: Seguridad_Avatar,
    Tipo_movil: Tipo_movil,
    Codigo_movil: Codigo_movil,
    Asignacion_user: Asignacion_user,
    Distancia_Asignacion: Distancia_Asignacion,
    Fechahora_Ult_Pos_Asignacion: Fechahora_Ult_Pos_Asignacion,
    Location_Asignacion: Location_Asignacion,
    accuracy: accuracy,
    denuncia_dias: denuncia_dias,
    LatProc: LatProc,
    LngProc: LngProc,
    TelefenoSeguridad: TelefenoSeguridad,
    nomprioridad: nomprioridad,
    nameDescripcion: nameDescripcion,
    Turno_user_id: Turno_user_id,
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log('return axios.post: ', error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const insertaInicioProc = (
  denuncia_id,
  denunciaid,
  user_id,
  estado_asignacion,
  Codigo_Seguridad,
  Username_Seguridad,
  Seguridad_Avatar,
  Fechahora_Ult_Pos_Inicio,
  Location_Inicio,
  accuracy_Inicio,
  denuncia_dias,
  accessToken
) => {
  return axios.post(API_URL0 + "despacho/insertainicioproc", {
    denuncia_id: denuncia_id,
    denunciaid: denunciaid,
    user_id: user_id,
    estado_asignacion: estado_asignacion,
    Codigo_Seguridad: Codigo_Seguridad,
    Username_Seguridad: Username_Seguridad,
    Seguridad_Avatar: Seguridad_Avatar,
    Fechahora_Ult_Pos_Inicio: Fechahora_Ult_Pos_Inicio,
    Location_Inicio: Location_Inicio,
    accuracy_Inicio: accuracy_Inicio,
    denuncia_dias: denuncia_dias,
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const insertaTerminoProc = (
  denuncia_id,
  denunciaid,
  user_id,
  Fechahora_Ult_Pos_Termino,
  Location_Termino,
  accuracy_Termino,
  Id_Seguridad_turno,
  accessToken
) => {
  return axios.post(API_URL0 + "despacho/insertaterminoproc", {
    denuncia_id: denuncia_id,
    denunciaid: denunciaid,
    user_id: user_id,
    Fechahora_Ult_Pos_Termino: Fechahora_Ult_Pos_Termino,
    Location_Termino: Location_Termino,
    accuracy_Termino: accuracy_Termino,
    Id_Seguridad_turno: Id_Seguridad_turno,
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const lisDenMisAsig = async (
  estado,
  entidad,
  Codigo_Seguridad,
  Username_Seguridad,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenMisAsig";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Codigo_Seguridad,
      Username_Seguridad,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenTipo = async (
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  derivacion,
  accessToken
) => {
  try {
    
    let urlAux = API_URL + "lisDenTipo";
    const response = await axios.post(urlAux, {
      entidad,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      derivacion,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const register_parte = (
  parte_user_id,
  parte_nombres,
  parte_apellidos,
  parte_email,
  parte_ubica,
  parte_username,
  parte_entidad,
  parte_role,
  parte_dataDevice,
  parte_Infraccion_tipo,
  parte_Tipo_Infraccion,
  parte_Nom_Infraccion,
  parte_foto,
  parte_currentLatitude,
  parte_currentLongitude,
  parte_currentaccuracy,
  parte_Fechahora_Infraccion,
  parte_Num_Boleta,
  parte_Patente_PPU,
  parte_Fechahora_CitacionJPL,
  parte_Tipo_Vehiculo,
  parte_Marca,
  parte_Color,
  parte_Forma_Entrega,
  parte_Calle1,
  parte_Nuncalle,
  parte_Calle2,
  parte_Observacion,
  parte_dias,
  parte_LaborEducativa,
  accessToken,
  TextRUT,
  TextNOMBRE,
  TextDOMICILIO) => {
  let urlAux = API_URL0 + "partes/ingresoparte";
  return axios.post(urlAux, {
    parte_user_id,
    parte_nombres,
    parte_apellidos,
    parte_email,
    parte_ubica,
    parte_username,
    parte_entidad,
    parte_role,
    parte_dataDevice,
    parte_Infraccion_tipo,
    parte_Tipo_Infraccion,
    parte_Nom_Infraccion,
    parte_foto,
    parte_currentLatitude,
    parte_currentLongitude,
    parte_currentaccuracy,
    parte_Fechahora_Infraccion,
    parte_Num_Boleta,
    parte_Patente_PPU,
    parte_Fechahora_CitacionJPL,
    parte_Tipo_Vehiculo,
    parte_Marca,
    parte_Color,
    parte_Forma_Entrega,
    parte_Calle1,
    parte_Nuncalle,
    parte_Calle2,
    parte_Observacion,
    parte_dias,
    parte_LaborEducativa,
    TextRUT,
    TextNOMBRE,
    TextDOMICILIO,
  },
    {
      headers: { "x-access-token": accessToken }
    }).then(function (response) {
      if (response.data) {
        try {
          return response.data;
        } catch (error) {
          console.error(error);
        }
      }
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      let coderror = error.response.status;
      console.log(coderror);

      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};

const insertafotoParte = async (
  opcion,
  denuncia_id,
  username,
  email,
  nombres,
  denuncia_foto,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "partes/insertafotoParte";
    const response = await axios.post(urlAux, {
      opcion,
      denuncia_id,
      username,
      email,
      nombres,
      denuncia_foto
    },
      {
        headers: { "x-access-token": accessToken }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisMisPartes = async (
  estado,
  entidad,
  user_id,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "partes/lismispartes";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      user_id
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisMisPartesTipo = async (
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "partes/lismispartestipo";
    const response = await axios.post(urlAux, {
      entidad,
      id_prioridad,
      fecha_ini,
      fecha_fin
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const aggregate_horaTipoInfraccion = async (
  varEntidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "partes/aggregate_horaTipoInfraccion";
    const response = await axios.post(urlAux, {
      varEntidad,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      utc
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_horaTipo1Infraccion = async (
  varEntidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "partes/aggregate_horaTipo1Infraccion";
    const response = await axios.post(urlAux, {
      varEntidad,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      utc
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_FechaHora1Infraccion = async (
  estado,
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "partes/aggregate_FechaHora1Infraccion";
    const response = await axios.post(urlAux, {
      estado,
      utc,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      entidad
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisParteCerrada = async (
  estado,
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "partes/lispartecerrada";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const insertaNumBoleta = async (
  _id,
  infraccion_id,
  Num_Boleta,
  accessToken
) => {
  try {
    let urlAux = API_URL0 + "partes/insertanumboleta";
    const response = await axios.post(urlAux, {
      _id,
      infraccion_id,
      Num_Boleta,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const ingresoantecedenteCierreDeriva = (
  antecedente_antecedentes,
  antecedente_denuncia_id,
  antecedente_user_id,
  antecedente_username,
  antecedente_avatar,
  antecedente_foto,
  antecedente_pais,
  antecedente_accuracy,
  antecedente_currentLatitude,
  antecedente_currentLongitude,
  antecedente_medidas,
  antecedente_participantes,
  antecedente_detenidos,
  antecedente_cantidadDetenidos,
  denuncia_dias,
  accessToken
) => {
  return axios.post(API_URL + "ingresoantecedenteCierreDeriva", {
    antecedente_antecedentes: antecedente_antecedentes,
    antecedente_denuncia_id: antecedente_denuncia_id,
    antecedente_user_id: antecedente_user_id,
    antecedente_username: antecedente_username,
    antecedente_avatar: antecedente_avatar,
    antecedente_foto: antecedente_foto,
    antecedente_pais: antecedente_pais,
    antecedente_accuracy: antecedente_accuracy,
    antecedente_currentLatitude: antecedente_currentLatitude,
    antecedente_currentLongitude: antecedente_currentLongitude,
    antecedente_medidas: antecedente_medidas,
    antecedente_participantes: antecedente_participantes,
    antecedente_detenidos: antecedente_detenidos,
    antecedente_cantidadDetenidos: antecedente_cantidadDetenidos,
    denuncia_dias: denuncia_dias
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const lisDenCerradaTrack = async (
  estado,
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaTrack";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const lisDenCerradaMasSOS = async (
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaMasSOS";
    const response = await axios.post(urlAux, {
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};



const insertaplanintervencion = (
  plan_codigo,
  plan_nombre,
  plan_apellido,
  plan_telefono,
  plan_contacto1,
  plan_contacto2,
  plan_email,
  plan_entedad,
  plan_id,
  plan_rol,
  plan_dataVecino,
  plan_detalles,
  plan_latitud,
  plan_longitud,
  plan_direccion,
  plan_planNombre,
  accessToken
) => {
  return axios.post(API_URL + "insertaplanintervencion", {
    plan_codigo: plan_codigo,
    plan_nombre: plan_nombre,
    plan_apellido: plan_apellido,
    plan_telefono: plan_telefono,
    plan_contacto1: plan_contacto1,
    plan_contacto2: plan_contacto2,
    plan_email: plan_email,
    plan_entedad: plan_entedad,
    plan_id: plan_id,
    plan_rol: plan_rol,
    plan_dataVecino: plan_dataVecino,
    plan_detalles: plan_detalles,
    plan_latitud: plan_latitud,
    plan_longitud: plan_longitud,
    plan_direccion: plan_direccion,
    plan_planNombre: plan_planNombre
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}


const insertaplanintervencion_censo = (
  plan_censo_username,
  plan_censo_nombre,
  plan_censo_apellido,
  plan_censo_telefono,
  accessToken
) => {
  return axios.post(API_URL + "insertaplanintervencion_censo", {
    plan_censo_username: plan_censo_username,
    plan_censo_nombre: plan_censo_nombre,
    plan_censo_apellido: plan_censo_apellido,
    plan_censo_telefono: plan_censo_telefono
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const insertaplanintervencion_mas_seguridad = (
  plan_mas_seguridad_username,
  plan_mas_seguridad_nombre,
  plan_mas_seguridad_apellido,
  plan_mas_seguridad_telefono,
  accessToken
) => {
  return axios.post(API_URL + "insertaplanintervencion_mas_seguridad", {
    plan_mas_seguridad_username: plan_mas_seguridad_username,
    plan_mas_seguridad_nombre: plan_mas_seguridad_nombre,
    plan_mas_seguridad_apellido: plan_mas_seguridad_apellido,
    plan_mas_seguridad_telefono: plan_mas_seguridad_telefono
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const prom_dias_derivaciones = (
  id_unidad,
  accessToken
) => {
  return axios.post(API_URL + "prom_dias_derivaciones", {
    id_unidad: id_unidad
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const prom_dias_derivacionesTodas = (
  accessToken
) => {
  return axios.post(API_URL + "prom_dias_derivacionesTodas", {
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data.promedios;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const prom_minutos_Denuncias = (
  tipo_prioridad,
  derivacion,
  accessToken
) => {
  return axios.post(API_URL + "prom_minutos_Denuncias", {
    tipo_prioridad,
    derivacion
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const top5_Denuncias = (
  tipo_prioridad,
  derivacion,
  accessToken
) => {
  return axios.post(API_URL + "top5_Denuncias", {
    derivacion,
    tipo_prioridad
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const findDenunciaDistancia = (
  lat, 
  lng, 
  distancia, 
  derivada, 
  estado, 
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/findDenunciaDistancia", {
    lat: lat,
    lng: lng,
    distancia: distancia, 
    derivada: derivada, 
    estado: estado, 
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const findInfraccionesDistancia = (
  lat, 
  lng, 
  distancia, 
  dias, 
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/findInfraccionesDistancia", {
    lat: lat,
    lng: lng,
    distancia: distancia, 
    dias: dias, 
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

export default {
  register_user,
  login,
  logout,
  verifyUser,
  usertel,
  cambiopass,
  cambioavatar,
  register_denuncia,
  insertafotoDenuncia,
  lisDen,
  lisDenMis,
  contactoemergencia,
  aggregate_hora,
  aggregate_diaS,
  aggregate_FechaHora,
  listUsers,
  findUsergeotracks,
  findSeguridadgeotracks,
  findMasgeotracks,
  ingresoentidad,
  ingresoantecedente,
  insertafotoAntecedente,
  listAntecedentes,
  ingresoantecedenteCierre,
  Url_caso,
  lisDenCerrada,
  lisDenCerradaSinAnt,
  lisDenCerradaImagenFechas,
  regcall,
  regcalltel,
  regsms,
  regsmstel,
  lisDenCerradaImagenNumCaso,
  lisDenCerradaGrabacionFechas,
  lisDenCerradaGrabacionNumCaso,
  listUsersPerfil,
  cambioRol,
  cambioEstado,
  listUsersEstado,
  listUserPlanOnOF,
  cambioEstadoPlan,
  listUsersPlan,
  listPlanes,
  ingresoPlan,
  ingresoturno,
  enturno,
  salidaturno,
  listTurnos,
  findUserGeoUltima,
  listTurnosDisponible,
  findUserGeoUltimaGeo,
  insertadespacho,
  lisDenMisAsig,
  insertaInicioProc,
  insertaTerminoProc,
  lisDenTipo,
  aggregate_horaTipo,
  aggregate_horaTipo1,
  aggregate_FechaHora1,
  register_parte,
  insertafotoParte,
  lisMisPartes,
  lisMisPartesTipo,
  aggregate_horaTipoInfraccion,
  aggregate_horaTipo1Infraccion,
  aggregate_FechaHora1Infraccion,
  lisParteCerrada,
  insertaNumBoleta,
  ingresoantecedenteCierreDeriva,
  lisDenCerradaTrack,
  lisDenCerradaMasSOS,
  insertaplanintervencion,
  insertaplanintervencion_censo,
  insertaplanintervencion_mas_seguridad,
  prom_dias_derivaciones,
  prom_dias_derivacionesTodas,
  prom_minutos_Denuncias,
  top5_Denuncias,
  findDenunciaDistancia,
  findInfraccionesDistancia
};
